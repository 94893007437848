<template>
  <div class="mobile-sale-reserved-calendar">

    <div v-if="!isMobile" class="date-items">
      <button class="prev-date-btn" @click="changeTableDay(-1)">
        {{ $t('sales.invoices.labels.lastWeek') }}
      </button>
      <div class="date-items">
        <div class="date-content">
          <template v-for="i in 7">
            <button class="date-btn"
                    :class="selectedTableDay > -1 && i === selectedTableDay || selectedTableDay === -1 && i - 1 === weekIndex ? 'active' : ''"
                    @click="setTableDate(i)">
              {{ getTableDate($localeValidator('moment.dateWithWeek'), (futureWeeks * 7) + i - weekIndex - 1) }}
              <span v-if="tableDayReserveCount[i - 1] > 0" class="day-reserve-count">
                {{ tableDayReserveCount[i - 1] }}
              </span>
            </button>
          </template>
        </div>
      </div>
      <button class="next-date-btn" @click="changeTableDay(+1)">
        {{ $t('sales.invoices.labels.futureWeek') }}
      </button>
    </div>

    <div class="time-table" :class="isMobile ? 'mobile' : ''">
      <div class="time-label-row" @scroll="handleScroll('time-label-row', 'time-court-row')">
        <div v-if="timesList.length > 0"
             class="court-row time-label">
          <div class="court-name">{{ $t('sales.invoices.labels.timeCourt') }}</div>
          <mobile-select-time v-for="(item, time_index) in timesList[0].times"
                       :time="item.time"
                       :court="item.court"
                       :price="0"
                       :term-id="item.term_id"
                       :is-label="true"
                       :disabled="true"
                       :date="getTableDate()"
                       :selected-times="selectedTimes"/>
        </div>
      </div>

      <div class="time-court-row" @scroll="handleScroll('time-court-row', 'time-label-row')">
        <div class="time-table-body">
          <div v-if="courtItem.times && courtItem.times.length > 0"
               class="court-row"
               v-for="(courtItem, court_index) in timesList"
               :key="`court_${court_index}`">
            <div class="court-name">{{ courtItem ? courtItem.court_name : '' }}</div>
            <mobile-select-time v-for="(item, time_index) in courtItem.times"
                                :time="item.time"
                                :court="item.court"
                                :price="item.price"
                                :term-id="item.term_id"
                                :customer="item.customer"
                                :current-time="getCurrentHour(item)"
                                :type="item.type"
                                :disabled="item.status !== 1 || !item.active || parseInt(item.price) === 0 || getCurrentHour(item) || item.customer"
                                :date="getTableDate()"
                                :selected-times="selectedTimes"
                                @showUser="handleShowProfile(item)"
                                @time:selected="handleTimeSelected($event)"
                                @time:unselected="handleTimeUnselected($event)"/>
          </div>
        </div>
      </div>
    </div>

    <!-- show user details prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showUserPromptStatus"
      @close="showUserPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
<!--            <div @click="editUserPromptStatus = true">-->
<!--              <custom-icon icon="EDIT" color="warning"/>-->
<!--            </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.user.profile.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-invoice-profile :user-id="selectedUser.id" :invoice-id="selectedUser.invoiceId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show selected times prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="confirmTimesPromptStatus"
      @close="confirmTimesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('showPaymentModal')">
              <custom-icon icon="SAVE_PAY" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.invoices.labels.confirm') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="confirmTimesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-sale-invoice :action-type="newInvoice.actionType" :selected-times="selectedTimes"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <vs-button id="setTimeSelected" class="useral-action-button" @click="handleShowReserveConfirm"/>
  </div>
</template>

<script>
  import moment from 'moment-jalaali'
  import {getReservableTimes} from "../../../../http/requests/booking/sales"
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import UserInvoiceProfile from "../../sales/invoices/invoice/saleInvoiceModal";
  import {showLoading, hideLoading, getTimeFromServer} from "../../../../assets/js/functions";
  import InsertSaleInvoice from "../invoices/insert/insertSaleInvoice";
  import MobileSelectTime from "../../../../components/reserveTime/mobileSelectTime";

  export default {
    name: "mobileSalesCalendar",
    components: {MobileSelectTime, InsertSaleInvoice, UserInvoiceProfile, CustomIcon},
    metaInfo() {
      return {
        title: this.$t('sales.invoices.calendar.title')
      }
    },
    data() {
      return {
        confirmTimesPromptStatus: false,
        showUserPromptStatus: false,
        selectInvoiceTypeStatusPrompt: false,
        currentTime: moment(getTimeFromServer()).format(this.$localeValidator('moment.date')),
        isMobile: false,
        handleScrollTimer: 0,
        futureWeeks: 0,
        getCurrentHourTimer: 0,
        weekIndex: 0,
        selectedTableDay: -1,
        selectedUser: {},
        timesList: [],
        courtsList: [],
        reservedList: [],
        tableDayReserveCount: [],
        selectedTimes: {},
        newInvoice: {
          id: {
            value: '',
            isValid: true
          },
          actionType: {
            value: 1,
            label: this.$t('sales.invoices.actionTypes.present')
          },
          status: {},
          user: {
            id: 0,
            name: {
              value: '',
              isValid: true
            }
          },
          date: {
            value: moment(getTimeFromServer()).format(this.$localeValidator('moment.date')),
            isValid: true
          },
          finalPrice: 0,
          totalPrice: 0,
        },
        actions: [
          {
            toolbar: [],
            leftToolbar: [
              {
                id: 'changeTableType',
                icon: 'REFRESH',
                iconPack: 'useral',
                i18n: ''
              }
              // {
              //   id: {name: 'saleInvoices'},
              //   type: 'link',
              //   icon: 'CHEVRON_LEFT',
              //   iconPack: 'useral'
              // }
            ]
          }
        ]
      }
    },
    created() {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
      }, 50)
      this.currentTime = getTimeFromServer()
      this.isMobile = this.$store.state.windowWidth < this.$store.state.mobileScreen

      this.weekIndex = (moment(this.currentTime).day() + 1) % 7
      this.selectedTableDay = this.weekIndex + 1
      this.getReservableTimes(this.$store.state.helper.calendarDate)
      this.getReserveTableCount()
    },
    mounted () {
      setTimeout(() => {
        showLoading()
      }, 480)

      this.getCurrentHourTimer = setTimeout(() => {
        this.getCurrentHourTimer = 0
      }, 60000 - parseInt(moment(this.currentTime).format(this.$localeValidator('moment.second'))) * 1000)
    },
    methods: {
      getCurrentHour (item) {
        return moment(this.currentTime).format(this.$localeValidator('moment.dateHour')) === `${item.date} ${item.time.start_time.split(':')[0]}`
      },
      getTableDate(formatValue = this.$localeValidator('moment.date'), days = (this.futureWeeks * 7) + this.selectedTableDay - this.weekIndex - 1) {
        if (this.isMobile) {
          return this.$store.state.helper.calendarDate
        } else {
          return moment(this.currentTime).locale(this.$locale.getLanguage()).add(days, 'days').format(formatValue)
        }
      },
      setTableDate(index = this.selectedTableDay) {
        this.selectedTableDay = index
        const days = (this.futureWeeks * 7) + index - this.weekIndex - 1
        this.timesList = []
        this.getReservableTimes(days)
      },
      changeTableDay(status) {
        if (status > 0) {
          this.futureWeeks += 1
          this.selectedTableDay = 1
        } else {
          this.futureWeeks -= 1
          this.selectedTableDay = 7
        }
        this.timesList = []
        this.setTableDate()
        this.getReserveTableCount()
      },

      getReservableTimes(days) {
        showLoading()
        let date = ''
        if (this.isMobile) {
          date = days
        } else {
          date = this.getTableDate(this.$localeValidator('moment.date'), days)
        }
        if (date) {
          getReservableTimes(1, ['type=1', `date=${date}`]).then((response) => {
            const data = response.data.data
            let totalTimes = {}
            data.forEach(time => {
              if (!totalTimes[`court_${time.court.id}`]) {
                let court = time.court
                court.times = []
                totalTimes[`court_${time.court.id}`] = court
              }

              switch (time.status) {
                case 0:
                  time.type = 'closed'
                  break

                case 1:
                  time.type = time.active ? 'selectable' : 'disable'
                  break

                case 2:
                  time.type = 'reserved'
                  break
              }
              totalTimes[`court_${time.court.id}`].times.push(JSON.parse(JSON.stringify(time)))
            })
            this.timesList = Object.values(totalTimes)
            hideLoading()
          })
            .catch(error => {
              hideLoading()
            })
        } else {
          hideLoading()
        }

      },
      handleTimeSelected(selectedTime) {
        delete selectedTime.court.times
        this.selectedTimes[`${selectedTime.date}_${selectedTime.time.id}_${selectedTime.court.id}`] = selectedTime
        if (Object.values(this.selectedTimes).length === 1) {
          this.actions[0].toolbar.push({
            id: 'setTimeSelected',
            icon: 'CHECK',
            iconPack: 'useral',
            color: 'success',
            badge: {
              value: 0
            },
            permission: 'invoice.create'
          })
        }
        setTimeout(() => {
          let actions = JSON.parse(JSON.stringify(this.actions))
          actions[0].toolbar[0].badge.value = Object.values(this.selectedTimes).length
          this.$store.dispatch('updateNavbarActions', actions[0])
        }, 100)
        this.getReserveTableCount()

      },
      handleTimeUnselected(unselectedTime) {
        delete this.selectedTimes[`${unselectedTime.date}_${unselectedTime.time.id}_${unselectedTime.court.id}`]
        setTimeout(() => {
          let actions = JSON.parse(JSON.stringify(this.actions))
          if (Object.values(this.selectedTimes).length === 0) {
            this.actions[0].toolbar.splice(0, 1)
            actions[0].toolbar.splice(0, 1)
          } else {
            actions[0].toolbar[0].badge.value = Object.values(this.selectedTimes).length
          }
          this.$store.dispatch('updateNavbarActions', actions[0])
        }, 100)
        this.getReserveTableCount()
      },
      handleShowReserveConfirm () {
        if (Object.values(this.selectedTimes).length > 0) {
          this.confirmTimesPromptStatus = true
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.invoices.validators.noTime'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
      },
      getReserveTableCount (initialDate) {
        const selectedTimes = Object.keys(this.selectedTimes)
        let timeCounts = []
        for (let i = 1; i <= 7; i++) {
          let count = 0
          let date = ''
          if (this.isMobile) {
            date = initialDate
          } else {
            date = this.getTableDate(this.$localeValidator('moment.date'), (this.futureWeeks * 7) + i - this.weekIndex - 1)
          }
          selectedTimes.forEach((time) => {
            if (time.split('_')[0] === date) {
              count++
            }
          })
          timeCounts.push(count)
        }
        this.tableDayReserveCount = timeCounts
      },
      handleShowProfile (item) {
        if (item.customer) {
          this.selectedUser = item.customer
          this.selectedUser.invoiceId = item.invoice_id
          if (checkUserPermissions('user.show') && checkUserPermissions('invoice.show')) {
            this.showUserPromptStatus = true
          }
          // let routeData = this.$router.resolve({name: 'saleInvoice', params: {id: item.invoice_id}})
          // window.open(routeData.href, '_blank')
        }
      },

      handleScroll (scrollFromElement, scrollToElement) {
        if (document.getElementsByClassName(scrollFromElement).length > 0) {
          const {clientHeight, scrollHeight, scrollTop} = document.getElementsByClassName(scrollFromElement)[0]

          document.getElementsByClassName(scrollToElement)[0].scrollTop = scrollTop
          document.getElementsByClassName('time-label-row')[0].style.height = `${document.getElementsByClassName('time-court-row')[0].offsetHeight}px`
        }
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
    },

    watch: {
      getCurrentHourTimer: {
        handler (val) {
          if (val === 0) {
            this.$forceUpdate()
            this.getCurrentHourTimer = setTimeout(() => {
              this.getCurrentHourTimer = 0
            }, 60000)
          }
        }
      },
      '$store.state.helper.calendarDate': {
        handler (val) {
          this.isMobile = this.$store.state.windowWidth < this.$store.state.mobileScreen
          this.timesList = []
          this.getReservableTimes(val)
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../assets/scss/vuexy/variables";
  .mobile-sale-reserved-calendar {
    background-color: #ffffff;

    .date-items {
      display: flex;
      align-items: center;
      background-color: #f0f0f0;
      border: 1px solid #f0f0f0;

      .prev-date-btn,
      .next-date-btn {
        padding: 5px 20px;
        margin: 10px;
        background-color: #f8f8f8;
        border: 1px solid #cecece;
        border-radius: 0.5rem;

        &:hover:not([disabled]) {
          cursor: pointer;
          background-color: #f0f0f0;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      .date-items {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        overflow: auto;
        .date-content {
          display: flex;
          text-align: center;
          overflow-x: auto;
          padding: 5px;
          padding-top: 10px;

          &::-webkit-scrollbar {
            display: block;
            background: rgba(255, 255, 255, 0.50);
            height: 10px;
            width: 10px;
            border-radius: 0.5rem;
          }

          &::-webkit-scrollbar-thumb {
            display: block;
            background: rgba(206, 206, 206, 1);
            height: 6px;
            border-radius: .5rem;
          }

          .date-btn {
            position: relative;
            padding: 5px 20px;
            margin: 0 5px;
            background-color: #f8f8f8;
            border: 1px solid #cecece;
            border-radius: 0.5rem;
            white-space: nowrap;

            &:hover:not([disabled]) {
              cursor: pointer;
              background-color: #f0f0f0;
            }

            &.active {
              background-color: $sidebar-semi-bg;
              color: #ffffff;

              &:hover {
                background-color: #1b2027;
              }
            }

            &:disabled {
              cursor: not-allowed;
            }

            .day-reserve-count {
              position: absolute;
              top: -10px;
              right: calc(50% - 8px);
              height: 16px;
              width: 16px;
              background-color: $success;
              color: #ffffff;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .time-table {
      position: relative;
      /*height: calc(100vh - 225px);*/
      overflow-x: auto;
      overflow-y: auto;
      display: flex;
      /*flex-direction: column;*/
      /*align-items: center;*/
      /*align-content: start;*/
      /*flex-wrap: wrap;*/
      padding: 8px;
      /*padding-left: 170px;*/
      align-self: flex-start;
      justify-self: flex-start;
      direction: ltr;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }



      /*&::-webkit-scrollbar {*/
      /*  display: block;*/
      /*  background: rgba(255, 255, 255, 0.50);*/
      /*  height: 10px;*/
      /*  width: 10px;*/
      /*  border-radius: 0.5rem;*/
      /*}*/

      /*&::-webkit-scrollbar-thumb {*/
      /*  display: block;*/
      /*  background: rgba(206, 206, 206, 1);*/
      /*  height: 6px;*/
      /*  border-radius: .5rem;*/
      /*}*/

      &.mobile {
        height: calc(100vh - 100px);
      }

      .time-label-row {
        width: 60px;
        height: calc(100% + 10px);
        overflow: auto;
        padding-bottom: 10px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }

        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          padding-bottom: 0;
        }
      }

      .time-court-row {
        width: calc(100% - 60px);
        height: calc(100% + 10px);
        overflow: auto;



        &::-webkit-scrollbar {
          display: block;
          background: rgba(255, 255, 255, 0.50);
          height: 10px;
          width: 10px;
          border-radius: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          background: rgba(206, 206, 206, 1);
          height: 6px;
          border-radius: .5rem;
        }
      }

      .time-table-body {
        display: flex;
        -webkit-box-orient: revert;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row;
        flex-direction: row;
        position: relative;
        /*align-self: flex-start;*/
        /*justify-self: flex-start;*/
      }

      .court-row {
        position: relative;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        width: 150px;

        @media screen and (max-width: 768px) {
          width: 140px;
        }

        .court-name {
          width: 100%;
          line-height: 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $sidebar-semi-bg;
          /*border-radius: 0.5rem 0.5rem 0 0;*/
          position: relative;
          align-self: flex-start;
          padding: 0 5px;
          color: #ffffff;
          font-weight: 500;
          border-right: 1px solid #cdcdcd;
          border-bottom: 1px solid #cdcdcd;
          z-index: 90;


          @supports (position: sticky) {
            position: -webkit-sticky;
            position: sticky;
            top: -1px;
          }
        }

        &:first-child {
          .court-name {
            /*border-radius: 0.5rem 0 0 0;*/
          }
        }

        &:last-child:not(.time-label) {
          .court-name {
            border-radius: 0 0.5rem 0 0;
            border-right: none;
          }

          .select-hour-box:last-child {
            button {
              border-radius: 0 0 0.5rem 0;
            }
          }
        }
      }

      .time-label {
        position: relative;
        /*position: -webkit-sticky;*/
        /*align-self: flex-start;*/
        /*justify-self: flex-start;*/
        z-index: 100;
        width: 60px;


        @supports (position: sticky) {
          position: -webkit-sticky;
          position: sticky;
          left: -10px;
        }

        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          position: -webkit-sticky;
          position: sticky;
          left: -10px;
        }

        .court-name {
          z-index: 101;
          border-radius: 0.5rem 0 0 0;
          font-size: 12px;
        }

        .select-hour-box {
          position: relative;
          height: 45px;
          /*left: 0;*/
          /*right: 0;*/

          .is-label {
            /*position: absolute;*/
            /*height: 100%;*/
            /*width: 100%;*/
            /*top: 0;*/
            /*left: 0;*/
            /*right: 0;*/
            /*bottom: 0;*/
            background-color: $sidebar-semi-bg;
            color: #ffffff;
            z-index: 100;
          }
        }

        .select-hour-box:first-child {
          .is-label {
            border-radius: 0 0.5rem 0 0;
          }
        }

        .select-hour-box:last-child {
          .is-label {
            border-radius: 0 0 0 0.5rem;
          }
        }
      }
    }
  }
</style>
