<template>
  <div>
    <template v-if="tableType === 'weekly'">
      <desktop-weekly-sales-calendar />
    </template>
    <template v-else-if="isMobile">
      <mobile-sales-calendar />
    </template>
    <template v-else>
      <desktop-sales-calendar />
    </template>

    <button id="changeTableType" v-show="false" @click="handleChangeTableType"></button>
  </div>
</template>

<script>
  import MobileSalesCalendar from "./mobileSalesCalendar";
  import DesktopSalesCalendar from "./desktopSalesCalendar";
  import DesktopWeeklySalesCalendar from "@/views/admin/sales/calendar/desktopWeeklySalesCalendar.vue";

  export default {
    name: "salesCalendar",
    components: {DesktopWeeklySalesCalendar, DesktopSalesCalendar, MobileSalesCalendar},
    metaInfo() {
      return {
        title: this.$t('sales.invoices.calendar.title')
      }
    },
    data () {
      return {
        tableType: 'daily'
      }
    },
    computed: {
      isMobile () {
        return this.$store.state.windowWidth < this.$store.state.mobileScreen
      },
    },
    methods: {
      handleChangeTableType () {
        this.$store.dispatch('setPageTitle', '')
        switch (this.tableType) {
          case 'daily':
            this.tableType = 'weekly'
            break

          case 'weekly':
            this.tableType = 'daily'
            break
        }
      }
    }
  }
</script>

<style lang="scss">
</style>
